@font-face {
    font-family: 'Bliss 2';
    src: url('./fonts/Bliss2-Bold.eot');
    src: url('./fonts/Bliss2-Bold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Bliss2-Bold.woff2') format('woff2'),
        url('./fonts/Bliss2-Bold.woff') format('woff'),
        url('./fonts/Bliss2-Bold.ttf') format('truetype'),
        url('./fonts/Bliss2-Bold.svg#Bliss2-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bliss 2';
    src: url('./fonts/Bliss2-LightItalic.eot');
    src: url('./fonts/Bliss2-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Bliss2-LightItalic.woff2') format('woff2'),
        url('./fonts/Bliss2-LightItalic.woff') format('woff'),
        url('./fonts/Bliss2-LightItalic.ttf') format('truetype'),
        url('./fonts/Bliss2-LightItalic.svg#Bliss2-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Bliss 2';
    src: url('./fonts/Bliss2-Light.eot');
    src: url('./fonts/Bliss2-Light.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Bliss2-Light.woff2') format('woff2'),
        url('./fonts/Bliss2-Light.woff') format('woff'),
        url('./fonts/Bliss2-Light.ttf') format('truetype'),
        url('./fonts/Bliss2-Light.svg#Bliss2-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
